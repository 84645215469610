import React from "react";

const Stepper = ({ currentStep = 0, items = [] }) => {
  return (
    <ul className="steps mx-auto max-w-[800px] w-full">
      {items.map((item, key) => (
        <li
          key={key}
          data-content="●"
          className={`step font-semibold text-base ${
            key > 0 ? "before:!h-[5px]" : ""
          } ${
            currentStep >= 0 && key === 0
              ? "after:!bg-primary-50 after:!text-primary-600 text-primary-700"
              : (currentStep > 0 && key > 0 && currentStep === key)
              ? "before:!bg-primary-600 after:!bg-primary-50 after:!text-primary-600 text-primary-700"
              : "before:!bg-gray-200 after:!bg-gray-100 after:!text-gray-300 text-gray-700"
          }`}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default Stepper;
