import React, { useState, Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { BsArrowLeftShort } from "react-icons/bs";
import { Button } from "components";
import classNames from "classnames";

const ButtonBackConfirm = ({
  onConfirm,
  buttonText = "Kembali",
  text = "Apakah Anda yakin ingin meninggalkan halaman ini? Perubahan data Anda tidak akan tersimpan.",
}) => {
  const [isShow, setShow] = useState(false);
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <Fragment>
      <Button
        type="button"
        className="button-secondary-outline"
        startIcon={<BsArrowLeftShort className="text-gray-700" size={20} />}
        onClick={() => {
          if (isDirty) {
            setShow(true);
            return;
          }
          if (onConfirm) onConfirm();
        }}
      >
        {buttonText}
      </Button>
      <div
        className={classNames(
          ["modal", "modal-bottom", "sm:modal-middle"],
          { [`visible opacity-100 pointer-events-auto`]: isShow },
          { [`invisible opacity-0`]: !isShow }
        )}
      >
        <div className="modal-box">
          <h3 className="font-bold text-lg">Konfirmasi</h3>
          <p className="py-4">{text}</p>
          <div className="modal-action">
            <Button
              size="md"
              type="button"
              className="button-secondary-outline"
              onClick={() => setShow(false)}
            >
              Tidak
            </Button>
            <Button
              size="md"
              type="button"
              className="button-error"
              onClick={() => {
                setShow(false);
                if (onConfirm) onConfirm();
              }}
            >
              Ya
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ButtonBackConfirm;
