import { CustomSelectForm } from "../HookForm";

const DirectoratesSelection = ({ index, options, isLoading = false }) => {
  return (
    <CustomSelectForm
      showError={false}
      name={`entities.${index}.directorat`}
      placeholder="Direktorat"
      options={options}
      isLoading={isLoading}
    />
  );
};

export default DirectoratesSelection;
