import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";

const Spinner = ({ className = "" }) => {
  return (
    <div className="spinner-wrapper">
      <CgSpinner
        className={classNames(
          "spinner",
          [...className.split(" ")]
        )}
      />
    </div>
  );
};

export default Spinner;
