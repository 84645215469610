import { axiosInstance } from "app/interceptors";

export const getUserDetail = async (userId, signal) => {
    const { data } = await axiosInstance.get(`/user-management/v1/User/internal/${userId}`, { signal });
    return data;
  };

  export const getUserByHierarchy = async (params, signal) => {
    const { data } = await axiosInstance.get(`/user-management/v1/Employee/get-user-by-divisi-hierarchy`, { params, signal });
    return data;
  };

  export const getEmployees = async (params, signal) => {
    const { data } = await axiosInstance.get(`/user-management/v1/Employee/list`, { params, signal });
    return data;
  };