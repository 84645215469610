import authSlice from "redux/authSlice";
import oidcSlice from "redux/oidcSlice";
import internalSlice from "redux/internal-audit/internalSlice";

const rootReducer = {
  oidc: oidcSlice,
  auth: authSlice,
  internal: internalSlice,
};

export default rootReducer;
