import React, { useState } from "react";

import { Header, Sidebar } from "components";
import classNames from "classnames";

const BasicLayout = ({ children }) => {
  const [isOpen, setOpen] = useState(true);

  const toggleOpen = () => setOpen((curr) => !curr);

  return (
    <div>
      <div className="flex flex-col h-full min-h-screen">
        <div className="flex flex-1 overflow-hidden">
          <Sidebar isSidebarOpen={isOpen}>{children}</Sidebar>
          <div
            className={classNames(["flex-auto", "w-0", "ml-0"], {
              "!ml-[250px]": isOpen,
            })}
          >
            <Header isSidebarOpen={isOpen} toggleSidebar={toggleOpen} />
            <main
              className="w-auto h-full min-h-screen paragraph p-8 bg-gray-50 mt-16"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
              style={{ transition: "margin-left .2s" }}
            >
              <div className="h-full">{children}</div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
