import React, { useMemo } from "react";
import { map } from "lodash";
import route from "./route";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import { AuthLayout, BasicLayout, Spinner } from "components";
import { AuthConsumer } from "providers/authProvider";
import My404Component from "pages/My404Component";
import My403Component from "pages/My403Component";

import {
  createBrowserRouter,
  RouterProvider,
  defer,
  Navigate,
  useOutletContext,
} from "react-router-dom";

import { USER } from "services";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children, routeKey, type, path }) => {
  const { userAccessRights } = useOutletContext();

  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          if (path === "/") {
            if (!userAccessRights?.[routeKey]?.[type]) {
              const first = Object.keys(userAccessRights).find(
                (key) => userAccessRights[key].canView === true
              );
              if (first) {
                const firstRoute = route
                  .filter((x) => x.key === userAccessRights[first].key)
                  .map((x) => {
                    const firstPath = x.path.split("/").filter((x) => x);
                    if (firstPath.length === 1) return firstPath[0];
                    return null;
                  })
                  .filter((x) => x);
                if (firstRoute?.[0])
                  return <Navigate to={firstRoute?.[0]} replace />;
              }
            }
          }
          if (userAccessRights?.[routeKey]?.[type]) {
            return (
              <BasicLayout>
                {React.cloneElement(children, {
                  accessRight: userAccessRights?.[routeKey],
                })}
              </BasicLayout>
            );
          } else return <Navigate to="/unautorized" replace />;
        } else {
          signinRedirect();
          return (
            <div className="relative min-h-screen">
              <Spinner />
            </div>
          )
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);
  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <CALLBACK />,
        path: "/signin-oidc",
      },
      {
        element: <LOGOUT />,
        path: "/logout",
      },
      {
        element: <LOGOUT_CALLBACK />,
        path: "/signout-oidc",
      },
      {
        element: <SILENT_RENEW />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              const { data: userDetail } = await USER.getUserDetail(
                user?.UserId,
                signal
              );
              if (
                userDetail?.userAccess &&
                Array.isArray(userDetail?.userAccess)
              ) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "spi"
                );
                if (index > -1) {
                  const userAccess =
                    userDetail?.userAccess?.[index]?.menuModules;
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute
                routeKey={val.key}
                type={val.type}
                path={val.path}
              >
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unautorized" },
    ]);
  }, [user]);

  return <RouterProvider router={RouteElements} />;
};

export default Routes;
