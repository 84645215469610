import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";

import store from "./app/store";
import Routes from "./routes";
import { Spinner } from "components";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import moment from "moment";
import localization from "moment/locale/id";
import { AuthProvider } from "providers/authProvider";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";

import Modal from "react-modal";

Modal.setAppElement("#root");

moment.updateLocale("id", localization);

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Spinner />}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              maxSnack={3}
              disableWindowBlurListener
              autoHideDuration={3000}
            >
              <AuthProvider>
                <Routes />
              </AuthProvider>
              {process.env.NODE_ENV !== "production" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </SnackbarProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
