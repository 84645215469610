import React from "react";

const InternalAudit = React.lazy(() => import("pages/InternalAudit"));
const NewTaskAssignment = React.lazy(() => import("pages/InternalAudit/new"));
const DetailTaskAssignment = React.lazy(() =>
  import("pages/InternalAudit/detail")
);
const AuditSummary = React.lazy(() => import("pages/AuditSummary"));
const ExternalAudit = React.lazy(() => import("pages/ExternalAudit"));
const NewExternalAudit = React.lazy(() => import("pages/ExternalAudit/new"));
const DetailExternalAudit = React.lazy(() =>
  import("pages/ExternalAudit/detail")
);
const SummaryExternalAudit = React.lazy(() =>
  import("pages/ExternalAudit/summary")
);
const WorkPlan = React.lazy(() => import("pages/WorkPlan"));
const NewWorkPlan = React.lazy(() => import("pages/WorkPlan/new"));
const DetailWorkPlan = React.lazy(() => import("pages/WorkPlan/detail"));
const EditWorkPlan = React.lazy(() => import("pages/WorkPlan/edit"));

const Recommendations = React.lazy(() => import("pages/Recommendations"));
const RecommendationDetail = React.lazy(() =>
  import("pages/Recommendations/detail")
);

const ExternalRecommendations = React.lazy(() =>
  import("pages/ExternalAudit/recommendations")
);
const ExternalRecommendationDetail = React.lazy(() =>
  import("pages/ExternalAudit/recommendation_detail")
);
const Dashboard = React.lazy(() => import('pages/Dashboard'));

const routesName = [
  {
    path: "/dashboard",
    element: Dashboard,
    exact: true,
    key: "internal_task_assignments",
    type: "canView",
  },
  {
    path: "/",
    element: Dashboard,
    exact: true,
    key: "internal_task_assignments",
    type: "canView",
  },
  {
    path: "/annual-workplan",
    element: WorkPlan,
    exact: true,
    key: "annual_workplan",
    type: "canView",
  },
  {
    path: "/annual-workplan/new",
    element: NewWorkPlan,
    exact: true,
    key: "annual_workplan",
    type: "canView",
  },
  {
    path: "/annual-workplan/:workplanId/detail",
    element: DetailWorkPlan,
    exact: true,
    key: "annual_workplan",
    type: "canView",
  },
  {
    path: "/annual-workplan/:workplanId/edit",
    element: EditWorkPlan,
    exact: true,
    key: "annual_workplan",
    type: "canUpdate",
  },
  {
    path: "/task-assignments",
    element: InternalAudit,
    exact: true,
    key: "internal_task_assignments",
    type: "canView",
  },
  {
    path: "/task-assignments/new",
    element: NewTaskAssignment,
    exact: true,
    key: "internal_task_assignments",
    type: "canCreate",
  },
  {
    path: "/task-assignments/:taskId",
    element: DetailTaskAssignment,
    exact: true,
    key: "internal_task_assignments",
    type: "canView",
  },
  {
    path: "/task-assignments/:taskId/summary",
    element: AuditSummary,
    exact: true,
    key: "internal_task_assignments",
    type: "canView",
  },
  {
    path: "/external-audit",
    element: ExternalAudit,
    exact: true,
    key: "external_task_assignments",
    type: "canView",
  },
  {
    path: "/external-audit/new",
    element: NewExternalAudit,
    exact: true,
    key: "external_task_assignments",
    type: "canCreate",
  },
  {
    path: "/external-audit/:taskId",
    element: DetailExternalAudit,
    exact: true,
    key: "external_task_assignments",
    type: "canView",
  },
  {
    path: "/external-audit/:taskId/summary",
    element: SummaryExternalAudit,
    exact: true,
    key: "external_task_assignments",
    type: "canView",
  },
  {
    path: "/recommendations",
    element: Recommendations,
    exact: true,
    key: "internal_recommendations",
    type: "canView"
  },
  {
    path: "/recommendations/:taskId/:procedureId/:internalRecoId/:recoId",
    element: RecommendationDetail,
    exact: true,
    key: "internal_recommendations",
    type: "canView"
  },
  {
    path: "/external-recommendations",
    element: ExternalRecommendations,
    exact: true,
    key: "external_recommendations",
    type: "canView",
  },
  {
    path: "/external-recommendations/:taskId/:recoId",
    element: ExternalRecommendationDetail,
    exact: true,
    key: "external_recommendations",
    type: "canView",
  },
];

export default routesName;
