import { Suspense, useLayoutEffect } from "react";
import { useLoaderData, Await, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Spinner from "components/atoms/Spinner";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const AuthLayout = () => {
  const userData = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await
        resolve={userData}
        errorElement={<div className="text-error">Something went wrong!</div>}
        children={({ userAccessRights }) => (
          <ScrollToTop>{<Outlet context={{ userAccessRights }} />}</ScrollToTop>
        )}
      />
    </Suspense>
  );
};

export const useAccessRights = () => useOutletContext();

export default AuthLayout;
