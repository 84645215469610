import axios from "axios";
import { axiosInternalInstance, axiosInstance } from "app/interceptors";

const auditorsDummy = [
  {
    id: 61821,
    name: "Nick Schultz DVM",
    initial: "Jr.",
    role: "Ketua Tim",
    email: "euna.schaden@hotmail.com",
  },
  {
    id: 15120,
    name: "Carlos Reynolds",
    initial: "PhD",
    role: "Anggota Tim",
    email: "roel_graham99@hotmail.com",
  },
  {
    id: 78289,
    name: "Lloyd Schroeder",
    initial: "III",
    role: "Anggota Tim",
    email: "celestino.becker46@hotmail.com",
  },
  {
    id: 41677,
    name: "Dianna Moen",
    initial: "Jr.",
    role: "Anggota Tim",
    email: "trystan88@hotmail.com",
  },
  {
    id: 80448,
    name: "Gregory Fritsch V",
    initial: "II",
    role: "Ketua Tim",
    email: "kaylie_farrell31@yahoo.com",
  },
  {
    id: 14303,
    name: "Christian Monahan",
    initial: "DVM",
    role: "Anggota Tim",
    email: "cordell42@gmail.com",
  },
  {
    id: 44480,
    name: "Mr. Tom Keebler",
    initial: "DVM",
    role: "Ketua Tim",
    email: "tabitha78@gmail.com",
  },
  {
    id: 6882,
    name: "Doreen Gleichner IV",
    initial: "Jr.",
    role: "Anggota Tim",
    email: "tyshawn.becker51@yahoo.com",
  },
  {
    id: 64603,
    name: "Dianna Windler",
    initial: "V",
    role: "Anggota Tim",
    email: "ada.christiansen@yahoo.com",
  },
  {
    id: 52620,
    name: "Darla Oberbrunner",
    initial: "II",
    role: "Anggota Tim",
    email: "shyann94@hotmail.com",
  },
  {
    id: 69708,
    name: "Miss Tracy Corkery",
    initial: "IV",
    role: "Anggota Tim",
    email: "murl_feeney@hotmail.com",
  },
  {
    id: 45116,
    name: "Cody Shanahan",
    initial: "II",
    role: "Ketua Tim",
    email: "donnie_schmitt63@yahoo.com",
  },
  {
    id: 82656,
    name: "Paula Turcotte",
    initial: "II",
    role: "Ketua Tim",
    email: "danielle.fritsch13@hotmail.com",
  },
  {
    id: 34708,
    name: "Stewart Huel",
    initial: "V",
    role: "Ketua Tim",
    email: "raina.nader7@hotmail.com",
  },
  {
    id: 35772,
    name: "Saul Hettinger DVM",
    initial: "MD",
    role: "Anggota Tim",
    email: "barry_thompson39@gmail.com",
  },
  {
    id: 54893,
    name: "Ms. Lucia Jaskolski",
    initial: "III",
    role: "Anggota Tim",
    email: "justus_thiel@hotmail.com",
  },
  {
    id: 55949,
    name: "Laurence O'Kon",
    initial: "III",
    role: "Ketua Tim",
    email: "korbin.runolfsdottir25@yahoo.com",
  },
  {
    id: 2294,
    name: "Scott Witting-Becker",
    initial: "V",
    role: "Anggota Tim",
    email: "aiyana.nicolas@yahoo.com",
  },
  {
    id: 51036,
    name: "Isabel Von PhD",
    initial: "MD",
    role: "Ketua Tim",
    email: "nia_rippin@yahoo.com",
  },
  {
    id: 71507,
    name: "Courtney Hartmann",
    initial: "DVM",
    role: "Anggota Tim",
    email: "dan54@gmail.com",
  },
  {
    id: 23059,
    name: "Johnnie Corwin",
    initial: "IV",
    role: "Ketua Tim",
    email: "halle_witting@gmail.com",
  },
  {
    id: 27794,
    name: "Colin Schamberger",
    initial: "DDS",
    role: "Anggota Tim",
    email: "bernita_roberts57@gmail.com",
  },
  {
    id: 77682,
    name: "Patsy Stark",
    initial: "DVM",
    role: "Anggota Tim",
    email: "carli.trantow@hotmail.com",
  },
  {
    id: 49026,
    name: "Grace Hagenes MD",
    initial: "MD",
    role: "Ketua Tim",
    email: "marielle.oberbrunner40@gmail.com",
  },
  {
    id: 8210,
    name: "Wallace Kiehn",
    initial: "Sr.",
    role: "Anggota Tim",
    email: "clare.pagac@hotmail.com",
  },
  {
    id: 27228,
    name: "Bryant Fisher PhD",
    initial: "V",
    role: "Anggota Tim",
    email: "ismael56@gmail.com",
  },
  {
    id: 39232,
    name: "Josephine Kohler",
    initial: "I",
    role: "Ketua Tim",
    email: "naomi.heathcote52@yahoo.com",
  },
  {
    id: 76076,
    name: "Dolores Graham",
    initial: "Sr.",
    role: "Anggota Tim",
    email: "garrick.marvin83@yahoo.com",
  },
  {
    id: 81408,
    name: "Irving Olson-Miller",
    initial: "III",
    role: "Anggota Tim",
    email: "arvid.little81@gmail.com",
  },
  {
    id: 37979,
    name: "Emanuel Wolff-Gerhold",
    initial: "Jr.",
    role: "Ketua Tim",
    email: "vernice_krajcik10@yahoo.com",
  },
  {
    id: 28643,
    name: "Tomas Emmerich",
    initial: "DVM",
    role: "Anggota Tim",
    email: "michale.bayer@yahoo.com",
  },
  {
    id: 86376,
    name: "Velma Parker",
    initial: "IV",
    role: "Anggota Tim",
    email: "damian_bergnaum53@yahoo.com",
  },
  {
    id: 31871,
    name: "Silvia Gibson MD",
    initial: "Sr.",
    role: "Anggota Tim",
    email: "ollie.koss@hotmail.com",
  },
  {
    id: 80070,
    name: "Marshall Senger",
    initial: "Sr.",
    role: "Anggota Tim",
    email: "levi_leffler79@hotmail.com",
  },
  {
    id: 90036,
    name: "Mrs. Sharon Considine",
    initial: "Sr.",
    role: "Anggota Tim",
    email: "dorcas_effertz@hotmail.com",
  },
  {
    id: 94734,
    name: "Faith Schaden",
    initial: "Jr.",
    role: "Ketua Tim",
    email: "isac.streich74@yahoo.com",
  },
  {
    id: 74344,
    name: "Monica Anderson",
    initial: "II",
    role: "Ketua Tim",
    email: "golden.denesik@hotmail.com",
  },
  {
    id: 18038,
    name: "Ebony Roberts",
    initial: "Sr.",
    role: "Ketua Tim",
    email: "kennedi_boyle94@yahoo.com",
  },
  {
    id: 37440,
    name: "Mrs. Lynn Stark",
    initial: "DDS",
    role: "Anggota Tim",
    email: "kiana44@gmail.com",
  },
  {
    id: 52963,
    name: "Paula Kovacek",
    initial: "Sr.",
    role: "Ketua Tim",
    email: "cassandra.nader@hotmail.com",
  },
];
export const getAuditorsDummy = (params, signal) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(auditorsDummy), 1000);
  });
};

export const getDirectorates = async (signal) => {
  const { data } = await axiosInternalInstance.get(
    `/v1/master/directorat`,
    { signal }
  );
  return data;
};

export const getDivisions = async (params, signal) => {
  const { data } = await axiosInternalInstance.get(
    `/v1/master/division`,
    { params, signal }
  );
  return data;
};

export const geMasterDivisions = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `/master/v1/Division/list`,
    { params, signal }
  );
  return data;
};

export const geMasterPositions = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `/master/v1/Jabatan/list`,
    { params, signal }
  );
  return data;
};

export const fileService = async (payload) => {
  const { data } = await axiosInstance.post(`/file-service/v1/files`, payload, {
    params: {
      service_name: "mis-internal-audit"
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
