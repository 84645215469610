import { axiosInternalInstance } from 'app/interceptors';

export const getInternalAudit = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal`, { params, signal });
  return data;
}

export const getOneInternalAudit = async (id, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal/${id}`, { signal });
  return data;
}

export const updateInternalAudit = async (id, payload, signal) => {
  const {data} = await axiosInternalInstance.patch(`/v1/internal/${id}`, payload, { signal });
  return data;
}

export const getOneProcedure = async (internalId, procedureId, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal/${internalId}/${procedureId}`, { signal });
  return data;
}

export const updateOneProcedure = async (internalId, procedureId, payload) => {
  const {data} = await axiosInternalInstance.post(`/v1/internal/${internalId}/${procedureId}`, payload);
  return data;
}

export const updateReportSubmission = async (internalId, payload) => {
  const {data} = await axiosInternalInstance.put(`/v1/internal/${internalId}/report_submission`, payload);
  return data;
}

export const updateSubmission = async (internalId, payload) => {
  const {data} = await axiosInternalInstance.put(`/v1/internal/${internalId}/submission`, payload);
  return data;
}

export const createNewInternalAudit = async (payload) => {
  const {data} = await axiosInternalInstance.post(`/v1/internal`, payload);
  return data;
}

export const approveProject = async (id, payload) => {
  const {data} = await axiosInternalInstance.put(`/v1/internal/approval/${id}`, payload);
  return data;
}