import React, { forwardRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Datepicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import classNames from "classnames";

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, onClear, isError, placeholderText, disabled } = props;
  const defaultClassName =
    "flex flex-1 items-center !px-4 !py-2 border-[1px] border-gray-200 rounded-md bg-white w-full text-sm";
  return (
    <div
      className={classNames(...defaultClassName.split(" "), {
        [`!border-error-500`]: isError,
        [`!cursor-default`]: disabled,
        [`!bg-[#EFEFEF]`]: disabled
      })}
    >
      <input
        value={value}
        ref={ref}
        className="!p-0 w-full outline-none border-transparent text-gray-800 text-sm focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-transparent disabled:bg-[#EFEFEF]"
        readOnly
        placeholder={placeholderText}
        disabled={disabled}
        onClick={onClick}
      />
      {(value && !disabled) ? (
        <IoMdCloseCircle
          className="text-gray-400"
          size={20}
          cursor="pointer"
          onClick={onClear}
        />
      ) : (
        <AiOutlineCalendar
          size={20}
          className={classNames("text-gray-400", { "cursor-pointer": !disabled })}
          onClick={onClick}
        />
      )}
    </div>
  );
});

const CustomDatepickerForm = ({
  name,
  placeholder,
  showIcon = true,
  disabled = false,
  onClear,
  onChangeDate,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <Datepicker
            disabledKeyboardNavigation
            placeholderText={placeholder}
            value={value}
            selected={value}
            onChange={(date) => {
              onChange(date);
              if (onChangeDate) onChangeDate(date);
            }}
            disabled={disabled}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInput onClear={onClear} isError={invalid} placeholderText={placeholder} disabled={disabled} />}
            {...props}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default CustomDatepickerForm;
