import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";

let NEXT_PAGE = null;

const SelectStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#EAECF0",
    ":hover": {
      ...styles[":hover"],
      borderColor: "#EAECF0",
    },
    boxShadow: "none",
    borderRadius: "6px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "rgb(71 84 103)",
    fontSize: 14
  }),
  indicatorContainer: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? "rgb(71 84 103)" : "rgb(71 84 103)",
    ":hover": {
      ...styles[":hover"],
      color: "rgb(71 84 103)",
    },
  }),
  option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
    ...styles,
    cursor: isDisabled ? "not-allowed" : "auto",
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#01A24A"
      : isFocused
      ? "#EEFFF4"
      : undefined,
    color: isDisabled
      ? "hsl(0, 0%, 80%)"
      : isSelected ? "#fff" : isFocused
      ? "rgb(71 84 103)"
      : "rgb(71 84 103)",
  }),
};

const CustomSelect = ({
  optionsData,
  onChange,
  value,
  placeholder = "Cari ...",
  ...props
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (optionsData.length) {
      setOptions(optionsData.map((data) => ({
        value: data.value,
        label: data.label,
        ...data
      })))
    }
  }, [optionsData]);

  return (
    <ReactSelect
      {...props}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={SelectStyles}
      closeMenuOnScroll={false}
      onChange={onChange}
      classNamePrefix="my-select"
    />
  );
};

export default React.memo(CustomSelect);
