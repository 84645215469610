import React from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const PieChartComponent = ({ data = {}, options = {}, ...props }) => {
  return <Pie data={data} options={options} {...props} />;
};

export default PieChartComponent;
