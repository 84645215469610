import classNames from "classnames";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const defaultClassName = "w-full py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-gray-200";

const TextareaForm = ({ name, rows = 5, className="", ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <textarea
            name={name}
            className={classNames(
              ...defaultClassName.split(" "),
              ...className.split(" "),
              { [`!border-error-500`]: invalid }
            )}
            value={value}
            onChange={onChange}
            rows={rows}
            {...props}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default TextareaForm;
