import React, { Fragment } from 'react';

const Card = ({children}) => {
  return (
    <Fragment>
      <div className="bg-white rounded-xl border-[1px] border-[#EAECF0] relative">
        <div className="border-t-[6px] border-primary-600 rounded-tl-xl rounded-tr-xl absolute top-0 right-[2px] left-[2px]"></div>
        {children}
      </div>
    </Fragment>
  )
}

export default Card