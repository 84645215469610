import { axiosInternalInstance } from 'app/interceptors';

export const getRecommendations = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal_recommendation`, { params, signal });
  return data;
}

export const getOneRecommendation = async (id, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal_recommendation/${id}`, { signal });
  return data;
}

export const getRecommendationSummary = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/internal_recommendation/summary`, { params, signal });
  return data;
}

export const updateStatus = async (id, payload, signal) => {
  const {data} = await axiosInternalInstance.post(`/v1/internal_recommendation/${id}/approval`, payload, { signal });
  return data;
}

export const updateRecommendation = async (id, payload, signal) => {
  const {data} = await axiosInternalInstance.post(`/v1/internal_recommendation/${id}/follow_up`, payload, { signal });
  return data;
}