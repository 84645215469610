import React, {useRef} from "react";
import { useFormContext, Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import classNames from "classnames";

//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

Quill.register(Quill.import('attributors/style/font'), true);

// Don't forget corresponding css
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Quill.register(Size, true);

//Text indent
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});

Quill.register(IndentStyle, true);

const Editor = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ 'direction': 'rtl' }],
      [{ size: ["0.75em", "1em", "1.5em", "2.5em"] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],
      // ["link", "image", "video"],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
  formats: [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ],
};

const RichEditorForm = ({
  name,
  placeholder = "Write description",
  className = "",
  ...props
}) => {
  const { control } = useFormContext();
  const quillRef = useRef(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div data-text-editor="form-editor" className="w-full">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              placeholder={placeholder}
              value={value}
              modules={Editor.modules}
              formats={Editor.formats}
              onChange={(text) => {
                onChange(text);
              }}
              className={classNames(className.split(" "), {
                [`has-error`]: invalid
              })}
              bounds={`[data-text-editor="form-editor"]`} //for link editor to be not cut-off
            />
            {invalid && (
              <div className="text-xs text-error-600 mt-2">{error?.message}</div>
            )}
          </div>
        )
      }
    }
    />
  );
};

export default RichEditorForm;
