import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { MASTER, USER } from "services";

const initialState = {
  allAuditors: [],
  selectedAuditor: [],
  auditorsList: [],
  isLoadingAuditorsList: false,
  assignmentDetail: null,
};

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const internalSlice = createSlice({
  name: "internalAudit",
  initialState,
  reducers: {
    setLoadingAuditorList: (state, action) => {
      state.isLoadingAuditorsList = action.payload;
    },
    setAuditorListData: (state, action) => {
      state.auditorsList = action.payload;
    },
    setAllAuditors: (state, action) => {
      state.allAuditors = action.payload;
    },
    setAssignmentDetail: (state, action) => {
      state.assignmentDetail = action.payload;
    },
    setSelectedAuditor: (state, action) => {
      let temp = state.selectedAuditor;
      if (Array.isArray(action.payload) && !action.payload.length) {
        state.selectedAuditor = [];
      } else if (
        !isEmpty(action.payload) &&
        action.payload.hasOwnProperty("idUser")
      ) {
        if (
          state.selectedAuditor.findIndex((x) => x.idUser === action.payload.idUser) >
          -1
        ) {
          temp = state.selectedAuditor.filter(
            (x) => x.idUser !== action.payload.idUser
          );
        } else {
          temp = [...state.selectedAuditor, action.payload];
        }
        state.selectedAuditor = temp;
      }
    },
    setRoleAuditor: (state, action) => {
      let temp = state.selectedAuditor;
      if (!isEmpty(action.payload) && action.payload.hasOwnProperty("idUser")) {
        const idx = state.selectedAuditor.findIndex(
          (x) => x.idUser === action.payload.idUser
        );
        if (idx > -1) {
          temp[idx] = action.payload;
        } else {
          temp = [...state.selectedAuditor, action.payload];
        }
        state.selectedAuditor = temp;
      }
    },
  },
});

// this is for dispatch
export const {
  setSelectedAuditor,
  setLoadingAuditorList,
  setAuditorListData,
  setAssignmentDetail,
  setAllAuditors,
  setRoleAuditor,
} = internalSlice.actions;

export const getAuditorsFiltered = (params) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingAuditorList(true));
    try {
      const allAuditors = getState().internal.allAuditors;
      let datas = allAuditors;
      if (params.keyword) {
        datas = allAuditors.filter((x) =>
          x.name.toLowerCase().includes(params.keyword.toLowerCase())
        );
      }
      const limit = params.limit;
      const page = params.page;
      let newData = paginate(datas, limit, page);
      const total = datas.length;
      const pageCount = total > 0 && limit > 0 ? Math.ceil(total / limit) : 1;
      const next_pages = page < pageCount ? page + 1 : null;
      const newObject = {
        result: newData,
        pagination: {
          page,
          limit,
          total,
          previous_pages: page - 1 === 0 ? null : page - 1,
          next_pages,
        },
      };
      dispatch(setAuditorListData(newObject));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingAuditorList(false));
    }
  };
};

export const getAuditorsDummy = (params) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingAuditorList(true));
    try {
      const { data } = await USER.getEmployees();
      const auditors = data.map((x) => ({ ...x, role: null }));
      const limit = params.limit;
      const page = params.page;
      let newData = paginate(auditors, limit, page);
      const total = auditors.length;
      const pageCount = total > 0 && limit > 0 ? Math.ceil(total / limit) : 1;
      const next_pages = page < pageCount ? page + 1 : null;
      const newObject = {
        result: newData,
        pagination: {
          page,
          limit,
          total,
          previous_pages: page - 1 === 0 ? null : page - 1,
          next_pages,
        },
      };
      dispatch(setAllAuditors(auditors));
      dispatch(setAuditorListData(newObject));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingAuditorList(false));
    }
  };
};

// this is for configureStore
export default internalSlice.reducer;
