import { useQuery } from "@tanstack/react-query";
import { MASTER } from "services";

export const useDirectorates = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      return MASTER.getDirectorates(signal);
    },
    ...options
  });
};

export const useDivisions = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return MASTER.getDivisions({ ...params }, signal);
    },
    ...options
  });
};
