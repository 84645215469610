import React, { useState } from "react";
import classNames from "classnames";

const defaultClass =
  "tab tab-bordered text-sm font-semibold text-gray-500 border-transparent min-w-max";
const activeClass =
  "tab-active bg-primary-50 !text-primary-700 !border-b-[2px] !border-primary-700";

const Tabs = ({ items, onTabChanged }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tabs border-b-[1px] after:border-gray-300 scrollbar flex-nowrap overflow-x-auto clear-both">
      {items.map((item, key) => (
        <span
          key={key}
          className={classNames(...defaultClass.split(" "), {
            [activeClass]: activeTab === key,
          })}
          onClick={() => {
            setActiveTab(key);
            if (onTabChanged) onTabChanged(item.id);
          }}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
};

export default React.memo(Tabs);
