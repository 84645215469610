import { useDivisions } from "hooks/master";
import { CustomSelectForm } from "../HookForm";

const DivisionsSelection = ({ index, directoratObj = undefined }) => {
  const { data, status } = useDivisions(
    ["divisions", { directorat: directoratObj?.name }],
    { enabled: !!directoratObj?.name }
  );
  return (
    <CustomSelectForm
      showError={false}
      name={`entities.${index}.division`}
      placeholder="Divisi"
      options={
        data?.result
          ? data?.result?.map((x) => ({
              value: x.id,
              label: x.name,
              ...x,
            }))
          : []
      }
      isLoading={status === "loading"}
      isDisabled={!directoratObj?.name}
    />
  );
};

export default DivisionsSelection;
