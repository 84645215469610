import React from 'react'

const StatusBadge = ({ children, status }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (status) {
    case "Baru":
      defaultDotClass = "bg-bluelight-500";
      defaultWrapperClass = "bg-bluelight-50 text-bluelight-700";
      break;
    case "Program Ditambahakan":
      defaultDotClass = "bg-indigo-500";
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    case "Pelaksanaan Penugasan":
      defaultDotClass = "bg-purple-500";
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "BAPH":
      defaultDotClass = "bg-pink-500";
      defaultWrapperClass = "bg-pink-50 text-pink-700";
      break;
    case "Pelaporan":
      defaultDotClass = "bg-warning-500";
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    case "Draft":
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
    case "Closed":
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}>
      <div className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`} />
      {status}
    </div>
  )
}

export default StatusBadge;