import React, { forwardRef } from "react";
import Datepicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import classNames from "classnames";

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, onClear, isError, placeholderText, disabled } = props;
  const defaultClassName =
    "flex flex-1 items-center !px-4 !py-2 border-[1px] border-gray-200 rounded-md bg-white w-full text-sm text-gray-400";
  return (
    <div
      className={classNames(...defaultClassName.split(" "), {
        [`!border-error-500`]: isError,
        [`!cursor-default`]: disabled,
      })}
    >
      <input
        value={value}
        ref={ref}
        className="!p-0 w-full outline-none border-white text-gray-400 text-sm border-transparent focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-transparent"
        readOnly
        placeholder={placeholderText}
        disabled={disabled}
        onClick={onClick}
      />
      {(value && !disabled) ? (
        <IoMdCloseCircle
          className="text-gray-400"
          size={20}
          cursor="pointer"
          onClick={onClear}
        />
      ) : (
        <AiOutlineCalendar
          className={classNames("text-gray-400", { "cursor-pointer": !disabled })}
          size={20}
          onClick={onClick}
        />
      )}
    </div>
  );
});

const CustomDatepicker = ({
  placeholder,
  showIcon = true,
  disabled,
  onClear,
  value,
  onChange,
  ...props
}) => {
  
  return (
    <div className="w-full">
      <Datepicker
        disabledKeyboardNavigation
        value={value}
        selected={value}
        onChange={onChange}
        disabled={disabled}
        dateFormat="yyyy-MM-dd"
        customInput={<CustomInput onClear={onClear} placeholderText={placeholder} disabled={disabled} />}
        {...props}
      />
    </div>
  );
};

export default CustomDatepicker;
