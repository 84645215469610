import axiosInstance from "app/interceptors";

export const getDashboard = async (params, signal) => {
  const data = {
    result: {
      workplan: [
        {
          status: "Baru",
          value: 25,
        },
        {
          status: "Tolak",
          value: 15,
        },
        {
          status: "Approved",
          value: 10,
        },
      ],
      internal_recommendation: [
        {
          status: "Tuntas",
          value: 25,
        },
        {
          status: "Tidak Tuntas",
          value: 40,
        },
        {
          status: "Belum Tindak Lanjut",
          value: 5,
        },
      ],
      external: [
        {
          category: "BPK",
          value: 25,
        },
        {
          category: "BPKP",
          value: 40,
        },
        {
          category: "Inspektorat Jendral",
          value: 5,
        },
        {
          category: "DJPB",
          value: 10,
        },
        {
          category: "KAP",
          value: 15,
        },
        {
          category: "Dewas",
          value: 30,
        },
        {
          category: "Lain-lain",
          value: 20,
        },
      ],
      internal: [
        {
          status: "Baru",
          value: 25,
        },
        {
          status: "Pelaksanaan Penugasan",
          value: 40,
        },
        {
          status: "Close",
          value: 5,
        },
      ],
    },
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(data), 2500);
  });
  //   const {data} = await axiosInstance.get(`/hukum-peraturan/v1/dashboard`, { params, signal });
  //   return data;
};
