import { RiFileSearchLine } from "react-icons/ri";
import { FaChartBar } from "react-icons/fa";

const MENU_ITEMS = [
  {
    icon: <FaChartBar className="text-white w-[18px] h-[20px]" />,
    id: "internal_task_assignments",
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
    id: "internal_task_assignments",
    label: "Internal Audit",
    path: "/internal-audit",
    children: [
      {
        id: "annual_workplan",
        label: "Rencana Kerja Tahunan",
        path: "/annual-workplan",
      },
      {
        id: "internal_task_assignments",
        label: "Project Penugasan",
        path: "/task-assignments",
      },
      {
        id: "internal_recommendations",
        label: "Daftar Rekomendasi",
        path: "/recommendations",
      },
    ],
  },
  {
    icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
    id: "external_task_assignments",
    label: "External Audit",
    path: "/external-audit",
    children: [
      {
        id: "external_task_assignments",
        label: "Project Penugasan",
        path: "/external-audit",
      },
      {
        id: "external_recommendations",
        label: "Daftar Rekomendasi",
        path: "/external-recommendations",
      },
    ],
  },
];

export default MENU_ITEMS;
