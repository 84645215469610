import {axiosInstance, axiosInternalInstance} from "app/interceptors"

export const setRequestHeader = (token) => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    axiosInternalInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const unsetRequestHeader = () => {
    delete axiosInstance.defaults.headers.common['Authorization'];
    delete axiosInternalInstance.defaults.headers.common['Authorization'];
}