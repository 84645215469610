import { axiosInternalInstance } from 'app/interceptors';

export const getWorkPlan = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/annual_plan`, { params, signal });
  return data;
}

export const getOneWorkPlan = async (id, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/annual_plan/${id}`, { signal });
  return data;
}

export const createNewWorkPlan = async (payload) => {
  const {data} = await axiosInternalInstance.post(`/v1/annual_plan`, payload);
  return data;
}

export const updateWorkPlan = async (id, payload) => {
  const {data} = await axiosInternalInstance.patch(`/v1/annual_plan/${id}`, payload);
  return data;
}

export const deleteWorkPlan = async (id, signal) => {
  const {data} = await axiosInternalInstance.delete(`/v1/annual_plan/${id}`, { signal });
  return data;
}