import React, { Fragment } from 'react'

const CardHeader = ({children}) => {
  return (
    <Fragment>
      <div className="card-header">
        {children}
      </div>
    </Fragment>
  )
}

export default CardHeader