import React from "react";
import { Label } from "components";
import { Link } from "react-router-dom";

const My403Component = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Label className="text-4xl font-bold">You are not allowed to access this page</Label>
      &nbsp;
      <Link to="/logout" replace className="text-primary-700 underline">Logout</Link>
    </div>
  );
};

export default My403Component;
